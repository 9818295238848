import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import { LoginForm } from './login-form';
import { InputControlService } from '../../core/services/input-control.service';
import { ModalService } from '../../core/services/modal.service';
import { UserService } from '../../core/services/data/user.service';
import { AnalyticsService } from '../../core/services/analytics.service';
import { UtilitiesService } from '../../core/services/utilities.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginV2Component implements OnInit {
	public errorText;

	public formComponents = new LoginForm();

	public form: UntypedFormGroup;

	public extraButton;

	public faEye = faEye;

	public faEyeSlash = faEyeSlash;

	public passwordHidden = {
		password: true,
	};

	public errorOptions = {
		title: '',
		text: null,
		actions: [],
	};

	private author;

	constructor(
		private router: Router,
		private ics: InputControlService,
		private ms: ModalService,
		private us: UserService,
		private as: AnalyticsService,
		private uss: UtilitiesService,
	) { }

	ngOnInit() {
		this.form = InputControlService.toFormGroup(this.formComponents);

		this.author = this.us.author$;
		if (this.author) {
			this.router.navigate([`/author-dashboard/${this.author?.authorLink}/features`]).then();
		}
	}

	onSubmit(event) {
		event.preventDefault();
		const errors = this.ics.checkFormValid(this.form, this.formComponents);
		if (errors.hasErrors) {
			this.errorOptions.title = 'Sorry, Login Information Invalid';
			this.errorOptions.text = errors.errorText;
			this.ms.open('error-modal');
			return;
		}

		this.us.login(this.form.value.email.toLowerCase().trim(), this.form.value.password).subscribe((data: any) => this.handleLogin(data));
	}

	handleLogin(data) {
		this.as.trackLogin();

		if (!data.success) {
			// this.errorText = ['Sorry, Login Information Invalid'];
			// this.errorText.push('Your email address and/or password was incorrect. Please try again. If you don\'t remember'
			// 	+ ' your password you can always use the reset password function.');
			// this.extraButton = 'forgot';

			this.errorOptions.title = 'Sorry, Login Information Invalid';
			this.errorOptions.text = ['Your email address and/or password was incorrect. Please try again. If you don\'t remember'
				+ ' your password you can always use the reset password function.'];
			this.errorOptions.actions = [
				{ type: 'solid', text: 'Got it', callback: () => this.ms.close('error-modal') },
				{ type: 'text', text: 'Forgot Password', callback: () => this.router.navigate(['/forgot-password']).then() },
			];
			this.ms.open('error-modal');
			return;
		}

		if (data.message === 'Not Confirmed') {
			this.router.navigate([`/confirm-account/${data.user.authorLink}/login`]).then();
			return;
		}

		this.us.updateUser(data.user);
		this.as.identify(data.user.username);
		const supers = {
			'Logged In': true,
			Employee: this.us.isAdminOrSuperAdmin(),
		};

		if (this.us.isAdminOrSuperAdmin()) {
			// eslint-disable-next-line no-console
			console.log('Authorizing Editorial Owl');
			this.us.authorizeEditorialOwl(data.user, (res) => {
				// eslint-disable-next-line no-console
				console.log('Authorized Editorial Owl', res);
			});
		}

		this.as.superProperty(supers);
		this.as.trackEvent('Login', { type: 'Email' });
		this.as.trackGTMEvent('login', { type: 'email' });
		this.uss.checkForMembershipIntentRoute(); // checks if the user was trying to create a membership before registration, sets the route if true.
		// if (this.uss.route && this.uss.route.indexOf('login') !== -1) {
		// 	const newRoute = this.uss.route;
		// 	this.uss.saveRoute(null);
		// 	window.location.href = newRoute;
		// 	return;
		// }

		if (this.uss.route) {
			if (this.uss.route.includes('gold') || this.uss.route.includes('platinum')) {
				const newRoute = this.uss.route;
				this.uss.saveRoute(null);
				this.router.navigate([`/author-dashboard/${data.user.authorLink}/subscriptions/${newRoute}`]).then();
				return;
			}

			const newRoute = this.uss.route;
			this.uss.saveRoute(null);
			this.router.navigate([`/author-dashboard/${data.user.authorLink}/${newRoute}`]).then();
			return;
		}

		this.router.navigate([`/author-dashboard/${data.user.authorLink}/home`]).then();
	}

	togglePasswordAttribute(passwordElmId) {
		const passwordInput = document.getElementById(passwordElmId);
		if (passwordInput) {
			if (this.passwordHidden[passwordElmId]) {
				passwordInput.setAttribute('type', 'text');
				this.passwordHidden[passwordElmId] = false;
			} else {
				passwordInput.setAttribute('type', 'password');
				this.passwordHidden[passwordElmId] = true;
			}
		}
	}
}
