import { Injectable } from '@angular/core';
import { concatMap, tap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { CoreModule } from '../../core.module';
import { ServerConnectionService } from '../server-connection.service';
import { UserService } from './user.service';
import { StripeService } from '../stripe.service';

type SavedPaymentMethod = {
	_user: string;
	paymentMethodId: string;
	attachToCustomer?: boolean;
	customerId?: string;
}

@Injectable({
	providedIn: CoreModule,
})
export class SavedPaymentsMethodsService {
	store: SavedPaymentMethod[];

	unchanged = false;

	author;

	constructor(
		private userService: UserService,
		private scs: ServerConnectionService,
		private stripeService: StripeService,
	) {
		this.userService.author$.subscribe((author) => {
			this.author = author;
		});
	}

	public create(paymentMethod: SavedPaymentMethod) {
		this.unchanged = false;
		return this.scs.http$('POST', '/api/saved-payment-methods', null, paymentMethod);
	}

	public list(userId: string = this.author?._id) {
		if (this.store && this.unchanged) {
			return of({ savedPaymentMethods: this.store });
		}

		return this.scs.http$('GET', `/api/saved-payment-methods/${userId}`, null, null)
			.pipe(tap((data: any) => {
				this.store = data.savedPaymentMethods;
				this.unchanged = true;
			}));
	}

	public remove(paymentMethodId: string) {
		this.unchanged = false;
		return this.scs.http$('POST', '/api/saved-payment-methods/remove', null, { paymentMethodId });
	}

	public getSavedCards() {
		if (!this.author) {
			return of([]);
		}
		return forkJoin({
			savedPaymentMethodsResult: this.list(this.author._id),
			stripeCards: this.stripeService.getCards(this.author.stripeCustomerId),
		}).pipe(concatMap(({ savedPaymentMethodsResult, stripeCards }: { savedPaymentMethodsResult: any; stripeCards: any }) => {
			if (savedPaymentMethodsResult && stripeCards?.card?.cards?.data?.length) {
				const savedPaymentMethodIds = savedPaymentMethodsResult.savedPaymentMethods
					.map((savedPaymentMethod) => savedPaymentMethod.paymentMethodId);

				const applicableCards = stripeCards.card.cards.data.filter((card) => savedPaymentMethodIds.includes(card.id));
				return of(applicableCards);
			}

			return of([]);
		}));
	}
}
