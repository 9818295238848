import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '../../core/services/modal.service';
import { UserService } from '../../core/services/data/user.service';

@Component({
	selector: 'app-congrats-modal',
	templateUrl: './congrats-modal.component.html',
	styleUrls: ['./congrats-modal.component.scss'],
})
export class CongratsModalComponent {
	@Input() modalId;

	@Input() maskClose;

	@Input() user;

	@Input() learnMoreButton;

	@Input() buttonText;

	@Input() titleCode;

	@Input() code;

	@Input() url;

	@Input() memberRole;

	@Output() confirmChoice = new EventEmitter();

	@Output() refreshUser = new EventEmitter();

	public success;

	public urlLocalStorage;

	constructor(
		private ms: ModalService,
		private router: Router,
		private uss: UserService,
	) {
		this.urlLocalStorage = localStorage.getItem('url');
	}

	ok() {
		if (this.url) {
			window.open(this.url, '_blank');
		}
		this.confirmChoice.emit(true);
		this.ms.close(this.modalId);
	}

	cancel() {
		this.urlLocalStorage = localStorage.getItem('url');
		localStorage.removeItem('url');
		if (this.urlLocalStorage) {
			this.router.navigate([`/author-dashboard/${this.user}${this.urlLocalStorage}`]).then();
		} else if (this.modalId !== 'modal-promo' && !this.router.url.includes('features')) {
			this.router.navigate([`/author-dashboard/${this.user}/home`]).then();
		}
		this.ms.close(this.modalId);
		this.refreshUser.emit();
		this.uss.barRefresh(true);
	}

	learnMore() {
		this.confirmChoice.emit(false);
		this.ms.close(this.modalId);
	}
}
