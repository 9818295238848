import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { CoreModule } from '../core.module';

@Injectable({
	providedIn: CoreModule,
})
export class ModalService {
	private _openedModal = new Subject<string>();

	private modals: any[] = [];

	get openedModal$() {
		return this._openedModal.asObservable();
	}

	add(modal: any) {
		this.modals.push(modal);
	}

	remove(id: string) {
		const modalToRemove = _.find(this.modals, { id });
		this.modals = _.without(this.modals, modalToRemove);
	}

	open(id: string) {
		const modal = _.find(this.modals, { id });

		if (!modal) throw new Error(`Unable to find Modal with id: ${id}`);

		modal.open();
		this._updateOpenedModal(id);
	}

	close(id: string) {
		const modal = _.find(this.modals, { id });

		if (!modal) return;

		modal.close();
	}

	modalCheck() {
		let hasOpen = false;
		this.modals.some(
			(modal) => {
				if (modal.isOpen) {
					hasOpen = true;
					return true;
				}

				return false;
			},
		);
		return hasOpen;
	}

	_updateOpenedModal(id: string) {
		this._openedModal.next(id);
	}
}
