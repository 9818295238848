/**
 * Unlike most modals, this isn't marked as modal component in its file name, but it is a modal component.
 */

import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { loadStripe } from '@stripe/stripe-js/pure';
import { UntypedFormGroup } from '@angular/forms';
import { animate, transition, state, style, trigger } from '@angular/animations';
import * as moment from 'moment';
import { AddBookForm } from '../../author-dashboard/add-book/add-book-form';
import { InputControlService } from '../../core/services/input-control.service';
import { StripeService } from '../../core/services/stripe.service';
import { MemberRoleService } from '../../core/services/data/member-roles.service';
import { ModalService } from '../../core/services/modal.service';
import { UserService } from '../../core/services/data/user.service';
import { environment } from '../../../environments/environment';
import { ServerConnectionService } from '../../core/services/server-connection.service';
import { MembershipUpgradeService } from '../../core/services/data/membership-upgrade.service';
import { SavedPaymentsMethodsService } from '../../core/services/data/saved-payment-methods.service';

import { AnalyticsService } from '../../core/services/analytics.service';
import { SubscriptionAPIResponse } from '../../types/responses';
import { IMemberRole, IUser } from '../../types/datatypes';

interface customWindow extends Window {
	Rewardful?: any;
	dataLayer?: any;
	development?: any;
}
declare const window: customWindow;
@Component({
	selector: 'app-update-card',
	templateUrl: './update-card.component.html',
	styleUrls: ['./update-card.component.scss'],
	animations: [
		trigger('fadeOut1', [
			state('true', style({ opacity: '1' })),
			state('false', style({ opacity: '0' })),
			transition('true => false', animate('1s ease-in')),
		]),
		trigger('fadeIn1', [
			state('true', style({ opacity: '1' })),
			state('false', style({ opacity: '0' })),
			transition('false => true', animate('1ms')),
		]),
		trigger('fadeOut2', [
			state('true', style({ opacity: '1' })),
			state('false', style({ opacity: '0' })),
			transition('true => false', animate('1ms')),
		]),
		trigger('fadeIn2', [
			state('true', style({ opacity: '1' })),
			state('false', style({ opacity: '0' })),
			transition('false => true', animate('1s ease-in')),
		]),
	],
})

export class UpdateCardComponent implements OnInit, OnDestroy {
	@Input() customer;

	@Input() cardSelected;

	@Input() currentSubscription;

	@Output() emitRefreshSuscription = new EventEmitter();

	@Output() refreshUser2 = new EventEmitter();

	@Output() planSelected = new EventEmitter();

	public platinumPlan: null | IMemberRole = null;

	public goldPlan: null | IMemberRole = null;

	public stripe;

	public elements;

	public card;

	public loading = false;

	public confirmText;

	public errorText = [];

	public successText;

	public buttonText = { confirm: 'Discard Changes', cancel: 'Go Back' };

	public checked = false;

	public available = true;

	public moment = moment;

	public form: UntypedFormGroup;

	public formComponents = new AddBookForm();

	public hasErrors;

	public goldMemberDiscount;

	public platinumMemberDiscount;

	public serviceLoading = true;

	private stripeKey;

	private development;

	private modalSub;

	constructor(
		private ss: StripeService,
		private mrs: MemberRoleService,
		private ms: ModalService,
		private uss: UserService,
		private router: Router,
		private scs: ServerConnectionService,
		private analyticsService: AnalyticsService,
		private membershipUpgradeService: MembershipUpgradeService,
		private savedPaymentMethodsService: SavedPaymentsMethodsService,
	) {
		this.development = window.development;
	}

	get author(): IUser {
		return this.membershipUpgradeService.author;
	}

	get selectedPlanName() {
		return this.membershipUpgradeService.selectedPlanName;
	}

	get plans() {
		return this.membershipUpgradeService.plans;
	}

	get plan() {
		return this.membershipUpgradeService.plan;
	}

	ngOnInit() {
		this.membershipUpgradeService.loading$.subscribe((loading: boolean) => this.init(loading));
		this.modalSub = this.ms.openedModal$.subscribe((openModalId) => {
			if (openModalId === 'update-card-form') {
				this.initStripe();
			}
		});
	}

	ngOnDestroy() {
		this.modalSub?.unsubscribe();
	}

	init(loading) {
		this.serviceLoading = loading;
		if (loading) return;

		this.goldPlan = this.membershipUpgradeService._getPlanByName('gold');
		this.platinumPlan = this.membershipUpgradeService._getPlanByName('platinum');

		this.serviceLoading = false;
		this.form = InputControlService.toFormGroup(this.formComponents);
		this.goldMemberDiscount = this.membershipUpgradeService.goldMemberDiscount;
		this.platinumMemberDiscount = this.membershipUpgradeService.platinumMemberDiscount;

		if (!this.currentSubscription) {
			this.ss.getSubscriptions(this.author._id).subscribe((result: any) => {
				if (result.success) {
					const { subscriptions } = result;
					const lastSubscriptions = subscriptions.filter((subscription) => subscription.isLastSubscription);
					this.currentSubscription = lastSubscriptions.length > 0 ? lastSubscriptions[0] : false;
				}
			});
		}
	}

	loadStripe() {
		this.initStripe();
	}

	// INIT FUNCTIONS

	// Load all needed stripe elements
	async initStripe() {
		this.stripeKey = this.development ? 'stripePKTest' : 'stripePKProd';
		this.stripe = await loadStripe(environment[this.stripeKey]);
		this.elements = this.stripe.elements();
		const cardStyle = {
			base: {
				color: '#32325d',
				fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
				fontSmoothing: 'antialiased',
				fontSize: '16px',
				'::placeholder': {
					color: '#aab7c4',
				},
			},
			invalid: {
				color: '#fa755a',
				iconColor: '#fa755a',
			},
		};
		this.card = this.elements.create('card', { style: cardStyle });
		this.card.mount('#card-element');
		this.card.on('focus', () => {
			const el = document.getElementById('card-element-errors');
			el.classList.add('focused');
		});
		this.card.on('blur', () => {
			const el = document.getElementById('card-element-errors');
			el.classList.remove('focused');
		});
		this.card.on('change', (event) => {
			this.displayError(event);
		});
	}

	// CLICKABLE FUNCTIONS
	updateSelect(event) {
		this.checked = !this.checked;
	}

	async updateCard() {
		// First, create paymentMethod
		const result = await this.stripe.createPaymentMethod({
			type: 'card',
			card: this.card,
		});
		// If paymentMethod fails trigger error and stop
		if (!result || !result.paymentMethod) {
			this.loading = false;
			this.hasErrors = true;
			this.available = true;
			this.errorText = [`There was a problem with your credit card data. <br><br> Check the following fields: 
			<br><br> - Card number <br> - Expiration date <br> - CVC Number <br> - C.P. Number`];
			this.ms.open('ab-error-modal');
		}
	}

	handleSubscriptionConfirm() {
		if (this.isGoldMember()) return this.upgradeSubscription();
		return this.createSubscription();
	}

	// Send customer information to create subscription
	async createSubscription() {
		this.errorText = [];
		this.loading = true;
		this.available = false;

		// First, create paymentMethod
		const result = await this.stripe.createPaymentMethod({
			type: 'card',
			card: this.card,
		});
		// If paymentMethod fails trigger error and stop
		if (!result?.paymentMethod) {
			this.loading = false;
			this.hasErrors = true;
			this.available = true;
			this.errorText = [`There was a problem with your credit card data. <br><br> Check the following fields: 
			<br><br> - Card number <br> - Expiration date <br> - CVC Number <br> - C.P. Number`];
			this.ms.open('ab-error-modal');
			return;
		}

		// Set customer payment method as default
		const ecommerceItem = this.analyticsService.generateAnalyticsEcommerceObject(this.plan._id, this.plan?.roleName, null, null, this.plan.price);
		this.analyticsService.trackBeginCheckout(this.plan.price, [ecommerceItem]);

		if (result.paymentMethod) {
			const { paymentMethod } = result;
			const paymentMethodId = typeof paymentMethod === 'string' ? paymentMethod : paymentMethod.id;
			this.savedPaymentMethodsService.create({ _user: this.author._id, paymentMethodId }).subscribe();
		}

		// Send info to create customer and subscription
		this.membershipUpgradeService.create(result.paymentMethod.id)
			.subscribe((data: any) => {
				if (data.success) {
					this.loading = false;
					this.available = true;
					this.ms.close('update-card-form');
					this.ms.open('congrats-subscription-success');

					this.analyticsService.clearEcommerce();
					this.analyticsService.trackPurchase(this.author.username, this.plan.price, [ecommerceItem]);
				} else {
					this.loading = false;
					this.available = true;
					this.errorText = [data.message];
					this.ms.open('ab-error-modal');
				}
			});
	}

	// Send customer information to upgrade subscription
	async upgradeSubscription() {
		this.errorText = [];
		this.loading = true;
		this.available = false;

		// Send info to update subscription
		this.membershipUpgradeService.upgrade()
			.subscribe((data: SubscriptionAPIResponse) => {
				if (data.success) {
					this.loading = false;
					this.available = true;
					this.successText = ['You\'ve successfully upgraded to Platinum!'];
					this.ms.open('subscription-modal');
				} else {
					this.loading = false;
					this.available = true;
					this.errorText = [data.message];
					this.ms.open('ab-error-modal');
				}
			});
	}

	reloadUser(user) {
		this.uss.barRefresh(true);
		this.ms.close('update-card-form');
	}

	// UI FUNCTIONS

	// After completing subscription, send user to dashboard
	refreshSuscrption(event) {
		this.emitRefreshSuscription.emit();
	}

	closeModal(event) {
		this.ms.close('update-card-form');
	}

	save(event) {
		event.preventDefault();
		this.errorText = [];
	}

	cancel() {
		if (this.form.pristine) {
			this.close();
		} else {
			this.confirmText = 'Are you sure you want to close and lose all your changes?';
			this.ms.open('ab-confirm-modal');
		}
	}

	handleConfirm(event) {
		if (event) {
			this.close();
		}
	}

	close() {
		if (this.router.routerState.snapshot.url.includes('subscriptions')) {
			this.planSelected.emit();
			this.ms.close('update-card-form');
		} else if (this.router.routerState.snapshot.url.includes('billing')) {
			this.ms.close('update-card-form');
		} else if (this.router.routerState.snapshot.url.includes('features')) {
			this.ms.close('update-card-form');
		} else if (this.router.routerState.snapshot.url.includes('billing')) {
			this.ms.close('update-card-form');
		} else if (this.router.routerState.snapshot.url.includes('promo-stacks')) {
			this.ms.close('update-card-form');
		} else if (this.router.routerState.snapshot.url.includes('membership')) {
			this.ms.close('update-card-form');
		} else {
			document.getElementById('update-card-form-body').scrollTo(0, 0);
			this.ms.close('update-card-form');
			this.router.navigate([`/author-dashboard/${this.author.authorLink}/home`]).then();
		}
	}

	selectPlan(type: string) {
		this.membershipUpgradeService.select(type);
	}

	refreshUser() {
		this.refreshUser2.emit();
	}

	goToPlans() {
		this.ms.close('update-card-form');
		this.router.navigate([`/author-dashboard/${this.author.authorLink}/subscriptions`]).then();
	}

	isFreeMember() {
		return this.uss.isFreeMember();
	}

	isGoldMember() {
		return this.uss.isGoldMember();
	}

	displayError(event) {
		const displayError = document.getElementById('card-element-errors');
		if (event.error) {
			displayError.textContent = event.error.message;
		} else {
			displayError.textContent = '';
		}
	}
}
