<app-modal [id]="modalId" [maskClose]="false">
  <aside class="modal">
      <div class="modal-body">
        <div class="close" (click)="cancel()"><span class="icon">&#x00D7;</span></div>
        <div class="header">
          <p class="text-uppercase">YOU ARE NOW A {{ memberRole }} MEMBER</p>
          <p class="congrats">Congrats!</p>
        </div>
        <div class="content">
          <p>Here are all the benefits you get:</p>
          <ul>
            <li>10% Discounts on Written Word Media promos</li>
            <li>Early Access To inventory</li>
            <li>Special Partner Discounts</li>
          </ul>
        </div>
        <div class="footer">
          <button class="button regular solid-green" (click)="cancel()">LET'S HAVE A LOOK</button>
        </div>
      </div>
  </aside>
  <div class="confirm-modal-background"></div>
</app-modal>
