import { InputText } from '../../classes/input-text';
import { InputBase } from '../../classes/input-base';

export class AddBookForm {
	amazonLink = new InputText({
		key: 'amazonLink',
		label: 'Amazon Book Link',
		placeholder: 'i.e., https://amazon.com/your-awesome-book-name/',
		required: true,
		blur: true,
	});

	title = new InputText({
		key: 'title',
		label: 'Book Title',
		required: true,
	});

	author = new InputText({
		key: 'author',
		label: 'Author',
		required: true,
	});

	description = new InputBase({
		key: 'description',
		label: 'Book Description for Promotion',
		controlType: 'textarea',
		maxLength: 400,
	});
}
