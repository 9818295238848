import { Component, ElementRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsService } from '../../core/services/analytics.service';
import { UserService } from '../../core/services/data/user.service';
import { OrderService } from '../../core/services/data/order.service';
import { ModalService } from '../../core/services/modal.service';

@Component({
	selector: 'app-member-menu',
	templateUrl: './member-menu.component.html',
	styleUrls: ['./member-menu.component.scss'],
})
export class MemberMenuComponent {
	public user: any;

	public show = false;

	constructor(
		public userService: UserService,
		private orderService: OrderService,
		private analyticsService: AnalyticsService,
		private router: Router,
		private elementRef: ElementRef,
		private ms: ModalService,
	) {
	}

	@HostListener('document:mousedown', ['$event'])
	public onGlobalClick(event): void {
		if (!this.show) return;

		if (!this.elementRef.nativeElement.contains(event.target)) {
			// clicked outside => close dropdown list
			this.show = false;
		}
	}

	get roleName() {
		const _role = this.userService.me?.memberRole?.roleName;

		return _role;
	}

	get role() {
		return this.roleName.toLowerCase();
	}

	logout() {
		this.analyticsService.trackLogout();

		this.userService.logout().subscribe(
			() => {
				this.orderService.clearOrderCookie();
				this.userService.updateUser({});
				window.location.href = '/features';
			},
		);
	}

	goToSupport() {
		(window as any).open('https://support.writtenwordmedia.com/category/hvmn7oxmjs-written-word-media', '_blank');
	}

	upgradePage() {
		const authorLink = this.userService?.me?.authorLink;

		if (this.role === 'free') {
			this.show = !this.show;
			return this.router.navigate(['/membership']).then();
		}

		if (this.role === 'gold') {
			this.show = !this.show;
			return this.router.navigate([`/author-dashboard/${authorLink}/billing`]).then();
		}

		return false;
	}

	contactSupport() {
		this.ms.open('contactFormModal');
	}
}
