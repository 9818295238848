<app-modal id="update-card-form" [maskClose]="false">
    <aside class="modal" *ngIf="!serviceLoading">
      <div class="modal-body" id="update-card-form-body">
        <div class="header">
          <div class="title-text" >Upgrade Your Written Word Media Membership</div>
          <div class="button regular close" (click)="cancel()"><span
              class="icon">&#x00D7;</span></div>
        </div>
        <section class="plans">
            <div class="gold">
              <span class="titlePlan">{{  goldPlan?.roleName }}</span>
              <p>For authors with a few titles</p>
              <span class="price">
                <span *ngIf="!isGoldMember() && goldMemberDiscount === 'true'">
                  <span class="through" >${{  goldPlan?.price }}/yr</span>  
                  ${{ goldPlan?.priceDiscounted }}<span class="firstYear"> first year</span>
                </span>
                <span  *ngIf="!isGoldMember() && goldMemberDiscount === 'false'">
                  <span class="price">${{ goldPlan?.price }}/yr </span>  
                </span>
              </span>
              
              <button id="submit-basic" *ngIf="isGoldMember()"  class="button regular solid-green submitActive">
                <span id="submit-basic-button-text" > <span class="check"></span>Current Plan</span>
              </button>
              <button id="submit-basic"
              (click)="selectPlan('gold')"
              *ngIf="!isGoldMember()" 
              [ngClass]="{'submitActive': selectedPlanName === 'gold', 'submitInactive': selectedPlanName !== 'gold'}"
              class="button regular solid-green">
                <span id="submit-basic-button-text" *ngIf="selectedPlanName !== 'gold'">Select Plan</span>
                <span id="submit-basic-button-text" *ngIf="selectedPlanName === 'gold'"> <span class="check"></span>Selected Plan</span>
              </button>
              <span *ngIf="!isGoldMember() && goldMemberDiscount === 'true'" >*${{ goldPlan?.priceDiscounted }} first year, 
                ${{ goldPlan?.price }}/yr thereafter
              </span>
            </div>
            <div class="platinum">
              <span class="titlePlan">{{ platinumPlan?.roleName }}</span>
              <p>For prolific authors and publishers</p>
              <span class="price">
                <span *ngIf="isFreeMember() && platinumMemberDiscount === 'true'">
                  <span class="through" >${{ platinumPlan?.price }}/yr</span>  ${{ platinumPlan?.priceDiscounted }}
                  <span class="firstYear"> first year</span>
                </span>
                <span  *ngIf="platinumMemberDiscount === 'false'">
                  <span class="price">${{ platinumPlan?.price }}/yr</span>
                </span>
              </span>
              <button id="submit-premium"
              (click)="selectPlan('platinum')"
              [ngClass]="{'submitActive': selectedPlanName === 'platinum', 'submitInactive': selectedPlanName !== 'platinum'}" 
              class="button regular solid-green">
                <span id="submit-basic-button-text" *ngIf="selectedPlanName !== 'platinum' && !isGoldMember()">Select Plan</span>
                <span id="submit-basic-button-text" *ngIf="selectedPlanName === 'platinum' && !isGoldMember()">
                  <span class="check"></span>Selected Plan
                </span>
                <span id="submit-basic-button-text" *ngIf="isGoldMember()">Upgrade to Platinum</span>
              </button>
              <span *ngIf="isFreeMember() && platinumMemberDiscount === 'true'">*${{ platinumPlan?.priceDiscounted }} first year, 
                ${{ platinumPlan?.price }}/yr thereafter</span>
            </div>
        </section>
        <p  class="notSure" *ngIf="isFreeMember()">Not Sure? <a (click)="goToPlans()" >Compare plans</a></p>
          <form [formGroup]="form" (submit)="save($event)">
            <div class="form-row" >
              <label for="card-element">Credit Card</label>
              <div id="card-element"></div>
              <div id="card-element-errors" role="alert"></div>
              <div class="checkbox">
                <input type="checkbox" id="agreeTerms" (change)="updateSelect($event)">I agree to Written Word Media's 
                <a href="https://www.writtenwordmedia.com/privacy-policy" target="_blank">Terms of Service and Privacy Policy.</a>
              </div>
              <div class="buttons">
                <button type="button" 
                (click)="handleSubscriptionConfirm()" 
                class="button regular solid-green" 
                [disabled]="!checked || selectedPlanName === '' || !available " >Upgrade</button>
                <div class="button regular outline-grey" (click)="cancel()">Cancel</div>
              </div>
            </div>
          </form>
      </div>
      <div *ngIf="loading" class="loader"><div></div><div></div><div></div><div></div></div>
    </aside>
    <div class="modal-background"></div>
  </app-modal>
  <app-confirm-modal 
  [modalId]="'ab-confirm-modal'" 
  [text]="confirmText" 
  [buttonText]="buttonText" 
  (confirmChoice)="handleConfirm($event)"></app-confirm-modal>
  <app-error-modal 
  [modalId]="'ab-error-modal'" 
  [text]="errorText"></app-error-modal>
  <app-success-modal 
  [modalId]="'subscription-modal'" 
  (confirmChoice)="reloadUser($event)" 
  [text]="successText" 
  [maskClose]="true"></app-success-modal>
  <app-congrats-modal 
  *ngIf="author"
  [modalId]="'congrats-subscription-success'" 
  [user]="author.authorLink" 
  (refreshUser)="refreshUser()" 
  [memberRole]="plan?.roleName"></app-congrats-modal>
