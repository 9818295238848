import { Component, ElementRef, Input, OnInit, OnDestroy } from '@angular/core';
import { ModalService } from '../../core/services/modal.service';

@Component({
	selector: 'app-modal',
	templateUrl: './modal.component.html',
})
export class ModalComponent implements OnInit, OnDestroy {
	@Input() id: String;

	@Input() maskClose: boolean;

	private element;

	private isOpen;

	constructor(
		private modalService: ModalService,
		private el: ElementRef,
	) {
		this.element = el.nativeElement;
	}

	ngOnInit(): void {
		const modal = this;
		if (!this.id) {
			return;
		}
		document.body.appendChild(this.element);
		if (this.maskClose && this.element) {
			this.element.on?.('click', (e) => {
				const { target } = e;
				if (!target.closest('.modal-body')) {
					modal.close();
				}
			});
		}
		this.modalService.add(this);
	}

	ngOnDestroy(): void {
		if (this.id) {
			this.modalService.remove(this.id.toString());
			this.element.remove();
		}
	}

	close(): void {
		this.element.style.display = 'none';
		this.isOpen = false;
		document.body.classList.remove('modal-open');
	}

	open(): void {
		this.element.style.display = 'block';
		this.isOpen = true;
		document.body.classList.add('modal-open');
	}
}
